import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';

import {
  Icon,
  Close,
  Header,
  Text,
  IconMedallion,
} from '@summer/ui-components/src';
import { hexToRgba } from '@summer/ui-components/src/helpers/utils/common';

import { ReactComponent as NoteIcon } from 'assets/icons/notes-edit.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send-email-3.svg';
import { api } from 'services/api';
import { COLORS } from 'styles/styleguide';

const useNotes = borrowerId => {
  return useQuery(['notes', String(borrowerId)], async () =>
    api.get(`/borrowers/${borrowerId}/notes`).then(res => res.data),
  );
};

const Note = ({ note, pos }) => {
  const { owner, note: noteText } = note;
  return (
    <NoteContainer pos={pos}>
      <NoteStyled pos={pos} title={format(new Date(note.createdAt), 'PPp')}>
        <Text color="light">{noteText}</Text>
      </NoteStyled>
      {pos === 'last' && (
        <Avatar title={`${owner.firstName} ${owner.lastName}`}>
          <Text bold size="small" color="light">
            {owner && owner.firstName[0] + owner.lastName[0]}
          </Text>
        </Avatar>
      )}
    </NoteContainer>
  );
};

const Notes = ({ notes }) => {
  const getOwner = note => {
    return note?.owner.uuid;
  };

  const getNextOwner = (notes, index) => {
    if (index === notes.length - 1) {
      return null;
    }
    return notes[index + 1].owner.uuid;
  };

  let currentOwner = getOwner(notes[0]);
  let nextrun = true;

  return notes.map((note, index) => {
    let pos = null;
    if (nextrun) {
      pos = 'first';
      nextrun = false;
    }
    if (currentOwner !== getNextOwner(notes, index)) {
      pos = 'last';
      nextrun = true;
      currentOwner = getNextOwner(notes, index);
    }
    return <Note key={note.uuid} note={note} pos={pos} />;
  });
};

export const NotePad = ({ borrowerId = 82821, adminUser }) => {
  const scrollToBottom = () => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  };
  const queryClient = useQueryClient();
  const inputRef = useRef(null);
  const bodyRef = useRef(null);
  const { data: notes, isLoading } = useNotes(borrowerId);
  const [showPad, setShowPad] = React.useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [notes, showPad]);

  const addNote = useMutation(
    newNote =>
      api.post(`/borrowers/${borrowerId}/notes`, {
        note: newNote,
      }),
    {
      // When mutate is called:
      onMutate: async newNote => {
        inputRef.current.innerText = '';
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['notes', String(borrowerId)]);

        // Snapshot the previous value
        const previousNotes = queryClient.getQueryData([
          'notes',
          String(borrowerId),
        ]);

        // Optimistically update to the new value
        if (previousNotes) {
          queryClient.setQueryData(
            ['notes', String(borrowerId)],
            [
              ...previousNotes,
              {
                uuid: Math.random().toString(),
                note: newNote,
                createdAt: new Date(),
                owner: {
                  firstName: adminUser.firstName,
                  lastName: adminUser.lastName,
                  profileImageUrl: adminUser.profileImageUrl,
                  uuid: adminUser.uuid,
                },
              },
            ],
          );
        }

        return { previousNotes };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousNotes) {
          queryClient.setQueryData(
            ['notes', String(borrowerId)],
            context.previousNotes,
          );
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(['notes', String(borrowerId)]);
      },
    },
  );

  if (!showPad) {
    return (
      <div
        onClick={() => {
          setShowPad(true);
        }}
        style={{
          position: 'fixed',
          bottom: '16px',
          right: '16px',
        }}
      >
        <IconMedallion
          color={isEmpty(notes) ? COLORS.darkerGrey : COLORS.darkGreen}
          SvgComponent={NoteIcon}
        />
      </div>
    );
  }

  return (
    <NotesContainer>
      <TopBar>
        <LeftSide>
          <IconContainer
            SvgComponent={NoteIcon}
            fill={COLORS.cobalt}
            width={24}
            height={24}
          />
          <Header h4>Notes</Header>
        </LeftSide>
        <Icon
          SvgComponent={Close}
          width={12}
          height={12}
          fill={COLORS.medGrey}
          onClick={() => setShowPad(false)}
        />
      </TopBar>
      <Body ref={bodyRef}>
        {!isLoading && !isEmpty(notes) && <Notes notes={notes} />}
      </Body>
      <Footer>
        <div style={{ borderTop: '1px solid #efefef' }} />
        <Inputs>
          <Avatar>
            <Text bold size="small" color="light">
              {adminUser && adminUser.firstName[0] + adminUser.lastName[0]}
            </Text>
          </Avatar>
          <InputContiner>
            <Input
              ref={inputRef}
              ariaLabel="Message"
              contentEditable={true}
              role="textbox"
              spellCheck={false}
              placeholder="Add a note"
            ></Input>
          </InputContiner>
          <Add
            onClick={() => {
              addNote.mutate(inputRef.current.innerText);
            }}
          >
            <Icon
              SvgComponent={SendIcon}
              width={20}
              height={20}
              fill={COLORS.white}
            />
          </Add>
        </Inputs>
      </Footer>
    </NotesContainer>
  );
};

// #region Styles

const NotesContainer = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 590px;
  height: 560px;
  padding: 0px;

  background: #ffffff;
  box-shadow: 0px 0px 22px -3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
`;

// #region NotePad Styles

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0px 24px;
  flex: none;
  align-self: stretch;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon-medallion {
    margin-right: 8px;
  }
`;
const IconContainer = styled(Icon)`
  margin-right: 8px;
  background: ${hexToRgba(COLORS.blueGrey, 0.3)};
  border-radius: 50%;
  padding: 8px;
`;

const Body = styled.div`
  overflow: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0px 24px;
`;
const Footer = styled.div`
  width: 100%;
  min-height: 80px;
  padding: 0px 24px;
`;

const Inputs = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 24px 0;

  &:last-child {
    margin-right: 0px;
  }

  > * {
    margin-right: 8px;
  }
`;

const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: ${COLORS.magentaDark};
  border-radius: 50%;
  overflow: hidden;
`;

const Input = styled.div`
  user-select: text;
  white-space: pre-wrap;
  word-break: break-word;
  max-height: 78px;
  overflow: scroll;
  width: 100%;
  outline: none;
`;
const InputContiner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 45px;
  max-height: 90px;
  border-radius: 20px;
  flex: 1;
  padding: 12px 12px;
  background-color: ${COLORS.blueGrey};

  .placeHolder {
    position: absolute;
    pointer-events: none;
  }

  [contentEditable='true']:empty:before {
    content: attr(placeholder);
    opacity: 0.6;
  }
`;

const Add = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: ${COLORS.azure};
  border-radius: 6%;
  cursor: pointer;
`;

// #endregion

// #region Note Style

const NoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  ${({ pos }) => pos === 'last' && 'margin-bottom: 16px;'}
`;

const NoteStyled = styled.div`
  padding: 12px;
  margin-bottom: 2px;
  background: ${COLORS.azure};
  border-radius: 2px 14px 14px 2px;
  ${({ pos }) => pos === 'first' && 'border-top-left-radius: 14px;'}
  ${({ pos }) => pos === 'last' && 'border-bottom-left-radius: 14px;'}
`;

// #endregion

// #endregion
